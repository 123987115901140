import {Component, Input, OnInit} from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {SideNavComponent} from './side-nav.component';
import {CommonModule} from '@angular/common';
import {AppAuthService} from './common/auth';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {MatDividerModule} from '@angular/material/divider';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {Company, CompanySite} from './model';
import {AppState} from './common/app-state';
import {filter, mergeMap, tap} from 'rxjs/operators';
import {UserService} from './common/service/user.service';
import {CompanyService} from './common/service/company.service';
import {MatListModule} from '@angular/material/list';
import {Dialog} from './common/dialog.service';
import {ServerVersionService} from './server-version.service';
import {NavigationService} from './common/navigation.service';

@Component({
  selector: 'app-top-nav[sidenav]',
  imports: [
    CommonModule, RouterLink, RouterLinkActive, MatToolbarModule, MatIconModule, MatButtonModule, MatMenuModule,
    MatTooltipModule, MatDividerModule, MatListModule
  ],
  templateUrl: 'top-nav.component.html',
  styleUrls: ['top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  @Input() sidenav!: SideNavComponent;
  companies!: Company[];
  selection?: { company?: Company, site?: CompanySite };
  version!: string;
  testEnv?: string;
  currentAction?: string;

  constructor(private dialog: Dialog, public authService: AppAuthService, public appState: AppState,
              private socialAuthService: SocialAuthService, private userService: UserService, private router: Router,
              private navigationService: NavigationService,
              private companyService: CompanyService, serverVersionService: ServerVersionService) {
    serverVersionService.subscribe(({client}) => this.version = client);
  }

  get manageLink(): string[] {
    if (this.companies?.length == 0) {
      return ['company', 'create'];
    }
    if (!this.selection?.company?.id) {
      return ['company', 'manage'];
    }
    return ['company', this.selection.company.id, 'manage'];
  }

  get showCompanyMenu(): undefined | boolean {
    if (!this.selection || !this.companies || !this.companies.length) {
      return false;
    }

    if (this.selection.company == undefined || this.companies.length > 1 || this.selection.company.site.length > 1) {
      return true;
    }

    return this.companies.length == 1 && this.companies[0].id != this.selection.company.id;
  }

  static openHelp() {
    const width = 1200;
    const height = 736;
    const screenLeft = window.screenLeft ?? window.screenX;
    const screenTop = window.screenTop ?? window.screenY;
    const screenWidth = window.innerWidth ?? document.documentElement.clientWidth ?? screen.width;
    const screenHeight = window.innerHeight ?? document.documentElement.clientHeight ?? screen.height;

    const left = (screenWidth / 2) - (width / 2) + screenLeft;
    const top = (screenHeight / 2) - (height / 2) + screenTop;

    const newWindow = window.open('/help',
      '_supplycanvas_help',
      `width=${width},height=${height},top=${top},left=${left},resizable=no,scrollbars=no,status=no,toolbar=no,menubar=no,location=no`);

    if (newWindow) {
      newWindow.focus();
      newWindow.resizeTo(width, height);
      newWindow.moveTo(left, top);
    } else {
      alert('Please allow popups for this website');
    }
  }

  logout() {
    this.authService.logout();
    this.socialAuthService.signOut(true).finally(() => {
      this.authService.logout();
      setTimeout(() => window.location.href = '/');
      // TODO redirect to www.supplycanvas.com
    });
  }

  async switchBack() {
    await this.authService.switchBack();
    await this.router.navigate(['/']);
  }

  ngOnInit(): void {
    this.appState.companySite$.subscribe((companySite) => this.selection = companySite);
    this.appState.companies$.subscribe((companies) => this.companies = companies);
    this.authService.user$
      .pipe(
        mergeMap(user => user ? [user] : []),
        tap(async (user) => this.companies = await this.companyService.listAll()),
        filter(user => user.incomplete),
        mergeMap(this.userService.profile),
        filter(user => !user.acceptedTerms),
      )
      .subscribe(async (data) => await this.dialog.acceptTerms(data));
    this.navigationService.currentRoute$.subscribe(route => this.currentAction = route?.action);
    const {hostname} = window.location;
    this.testEnv = hostname === 'app.supplycanvas.com' ? undefined : hostname.split('.')[0];
  }

  openHelp = () => TopNavComponent.openHelp();

  switchCompany = (company: Company, site: CompanySite) =>
    this.currentAction
      ? ['/company', company.id, 'site', site.id, this.currentAction]
      : ['/company', company.id, 'site', site.id];
}
